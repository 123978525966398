import Table from "../components/Table";
import "./css/About.css"


const About = () => {
    return (
        <div id="aboutCont">
            <Table />
        </div>
    )
}

export default About;