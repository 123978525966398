import ContactForm from "../components/ContactForm";
import "./css/Contact.css"




const Contact = () => {

    return ( 
        <div id="contactContainer">
            <ContactForm />
        </div>
    )
}

export default Contact;